import React from 'react';
import logo from './logo.png';
import './App.css';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoList: [],
            photoList: [],
            mainVideoSrc: ''
        };
    }

    componentDidMount() {
        if (window.location.search.indexOf('greekhill') > -1) {
            document.getElementById('titleLabel').innerHTML = "Bakamus Christmas Memories";
            document.getElementById('app').classList.remove("hide");
            
            var videos = [
                {
                    id: '01',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/20cfeab0-d2ce-481d-ac27-2ca53a69008b/1986-Bakamus-Christmas.mp4?sp=r&st=2020-12-20T00:53:12Z&se=2022-12-20T08:53:12Z&spr=https&sv=2019-12-12&sr=b&sig=3lx4P5zDzN0jk26CCXpiD3o4Ult3KT4zFil0VsFjHSg%3D',
                    poster: 'https://greatmemoriessta.blob.core.windows.net/customerdata/Thumbnails/SingingCarols.PNG?sp=r&st=2020-12-16T02:20:19Z&se=2022-12-16T10:20:19Z&spr=https&sv=2019-12-12&sr=b&sig=RqWhweTzZKheoMkBJiUvdbUnDYD%2FtOplaJ%2FJwFI%2FJtk%3D',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1986 Bakamus Christmas',
                    subtitle: '(Full Video - 01:14:48)',
                    canPlay: false
                },
                {
                    id: '02',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/20cfeab0-d2ce-481d-ac27-2ca53a69008b/1988-Bakamus-Christmas.mp4?sp=r&st=2020-12-20T01:09:17Z&se=2022-12-20T09:09:17Z&spr=https&sv=2019-12-12&sr=b&sig=9ehVcsHrwLS5zEtpZ104jyxzzT%2FlXkJjU1Nou91Af%2BE%3D',
                    poster: 'https://greatmemoriessta.blob.core.windows.net/customerdata/Thumbnails/88FamilyPhoto.PNG?sp=r&st=2020-12-17T00:21:27Z&se=2022-12-17T08:21:27Z&spr=https&sv=2019-12-12&sr=b&sig=Z2EerzEQ6SFtu6GCvDqUINGkr2nScXqMgiLK1z4bptw%3D',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1988 Bakamus Christmas',
                    subtitle: '(Full Video - 00:10:36)',
                    canPlay: false
                },
                {
                    id: '03',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/20cfeab0-d2ce-481d-ac27-2ca53a69008b/1990-Bakamus-Christmas.mp4?sp=r&st=2020-12-20T01:24:35Z&se=2022-12-20T09:24:35Z&spr=https&sv=2019-12-12&sr=b&sig=T0SIFAZe%2Fnl27hu%2BV5FdBgtQe%2FbBvk58GYFcfZ3demM%3D',
                    poster: 'https://greatmemoriessta.blob.core.windows.net/customerdata/Thumbnails/90VisitSanta.PNG?sp=r&st=2020-12-17T01:20:52Z&se=2022-12-17T09:20:52Z&spr=https&sv=2019-12-12&sr=b&sig=0HTpiKiAFsmFRAJmvm%2BPDrHzMXa1lkNRQSF%2BNa4WNMU%3D',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1990 Bakamus Christmas',
                    subtitle: '(Full Video - 00:42:56)',
                    canPlay: false
                },
                {
                    id: '04',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/20cfeab0-d2ce-481d-ac27-2ca53a69008b/1991-Bakamus-Christmas.mp4?sp=r&st=2020-12-20T01:11:10Z&se=2022-12-20T09:11:10Z&spr=https&sv=2019-12-12&sr=b&sig=hyh6wvfCJeeeHBB6zFJWW%2Bs6oHzWLlbPy20ebWF8IBY%3D',
                    poster: 'https://greatmemoriessta.blob.core.windows.net/customerdata/Thumbnails/1991BakamusXmas.PNG?sp=r&st=2020-12-20T01:26:45Z&se=2022-12-20T09:26:45Z&spr=https&sv=2019-12-12&sr=b&sig=AlYmJkJheXjkLltmNyZ08Hsc0BCWMY7h4Q92nw%2BGxw0%3D',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1991 Bakamus Christmas',
                    subtitle: '(Full Video - 00:25:27)',
                    canPlay: false
                },
                {
                    id: '16',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/20cfeab0-d2ce-481d-ac27-2ca53a69008b/1993-Bakamus-Christmas.mp4?sp=r&st=2020-12-20T01:16:19Z&se=2022-12-20T09:16:19Z&spr=https&sv=2019-12-12&sr=b&sig=vL4kXchlGOBAyG%2BShCRa8ZUrzPuv8P2iaw533hjf4Ls%3D',
                    poster: 'https://greatmemoriessta.blob.core.windows.net/customerdata/Thumbnails/93allietessa.PNG?sp=r&st=2020-12-18T06:12:54Z&se=2022-12-18T14:12:54Z&spr=https&sv=2019-12-12&sr=b&sig=sQWuijVgWNsc8Jq4%2FnLp1IFdrRqFXng%2FiSockYEJ1pA%3D',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1993 Bakamus Christmas',
                    subtitle: '(Full Video - 00:27:00)',
                    canPlay: false
                }
            ]
    
            var photos = [
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0050_a.jpg?sp=r&st=2020-12-20T01:56:50Z&se=2022-12-20T09:56:50Z&spr=https&sv=2019-12-12&sr=b&sig=mPYW%2FtkjBnkJMxURj0YnJTugZ7eQI1ZVQDE%2B0oLEHJE%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0051_a.jpg?sp=r&st=2020-12-20T01:57:31Z&se=2022-12-20T09:57:31Z&spr=https&sv=2019-12-12&sr=b&sig=3naooY%2BzYMC1A9hBKX4nhORzWKiG7AY0AOorCHg5FZo%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0054_a.jpg?sp=r&st=2020-12-20T02:02:53Z&se=2022-12-20T10:02:53Z&spr=https&sv=2019-12-12&sr=b&sig=Li7pIcN4o97BJvRIGPwGynxs11JEosJpcnA5vjDJwqY%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0055_a.jpg?sp=r&st=2020-12-20T02:03:22Z&se=2022-12-20T10:03:22Z&spr=https&sv=2019-12-12&sr=b&sig=ZcnW5vKV9Nreh7kqkGdO7hWctPsrpGiDoe%2FgcZvTuyc%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0058_a.jpg?sp=r&st=2020-12-20T02:03:56Z&se=2022-12-20T10:03:56Z&spr=https&sv=2019-12-12&sr=b&sig=WT83vOcXi%2F6z5uNAXzVD8FVe9njhFRYhGXzLt43yBmY%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0060_a.jpg?sp=r&st=2020-12-20T02:04:32Z&se=2022-12-20T10:04:32Z&spr=https&sv=2019-12-12&sr=b&sig=3KBDQWZ3Cz%2BJrkZHlzy9qia4xhPHIa2ilKwrdDpf3XE%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0061_a.jpg?sp=r&st=2020-12-20T02:04:52Z&se=2022-12-20T10:04:52Z&spr=https&sv=2019-12-12&sr=b&sig=oDGMsSLoIQ0e%2FF8au3YZKtj%2BxG1WDC7eS0OojkCsMDk%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0062_a.jpg?sp=r&st=2020-12-20T02:05:09Z&se=2022-12-20T10:05:09Z&spr=https&sv=2019-12-12&sr=b&sig=BAPh%2FQC0FuUvo2BDlmv%2BJi%2Fy3aw9gLEAwUHEvMntWcA%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0063_a.jpg?sp=r&st=2020-12-20T02:05:24Z&se=2022-12-20T10:05:24Z&spr=https&sv=2019-12-12&sr=b&sig=UruJkAekqjfnemJrGMgrlQkoIEdv38RITlVCjkw54gc%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0064_a.jpg?sp=r&st=2020-12-20T02:05:41Z&se=2022-12-20T10:05:41Z&spr=https&sv=2019-12-12&sr=b&sig=ImS6qSO5BalV7fa8FE8211jd7q5%2BUZal%2FqftXY7yWsY%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0100_a.jpg?sp=r&st=2020-12-20T02:06:04Z&se=2022-12-20T10:06:04Z&spr=https&sv=2019-12-12&sr=b&sig=QAqF34QU8cxnjFbiYCqezxfpUUxH4yG3Ig%2FxgBMWt%2Bk%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0101_a.jpg?sp=r&st=2020-12-20T02:06:19Z&se=2022-12-20T10:06:19Z&spr=https&sv=2019-12-12&sr=b&sig=1SCFk9fUC7Csl7YGucM39qtzr27RpxmP4gnudAeReDQ%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0105_a.jpg?sp=r&st=2020-12-20T02:06:39Z&se=2022-12-20T10:06:39Z&spr=https&sv=2019-12-12&sr=b&sig=luiJQsZm5ilxfYeqZWhO%2FZ%2BwKoDF8sl%2BL1BERTkoDL4%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0106_a.jpg?sp=r&st=2020-12-20T02:06:57Z&se=2022-12-20T10:06:57Z&spr=https&sv=2019-12-12&sr=b&sig=z%2BsNWQJSBxc4jFKCq8pVk5vaxaXdMktUKXHGGS5SenA%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0134_a.jpg?sp=r&st=2020-12-20T02:07:15Z&se=2022-12-20T10:07:15Z&spr=https&sv=2019-12-12&sr=b&sig=cfFX6DXID1eo0OYRyHdXDLbWjhshKYG1ogwLmM71oUA%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0136_a.jpg?sp=r&st=2020-12-20T02:07:32Z&se=2022-12-20T10:07:32Z&spr=https&sv=2019-12-12&sr=b&sig=%2FTli9854HhXSAbeXCR8D0zW7DA5GKU57mQHcd0L%2F5Nc%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0137_a.jpg?sp=r&st=2020-12-20T02:07:49Z&se=2022-12-20T10:07:49Z&spr=https&sv=2019-12-12&sr=b&sig=PVCpBXFyLbsTTfs0e6hGt8zbFDsvm%2FPVXRxXC2C9Z1g%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0138_a.jpg?sp=r&st=2020-12-20T02:08:06Z&se=2022-12-20T10:08:06Z&spr=https&sv=2019-12-12&sr=b&sig=hZKwbSq3a9VBZdjVJBk6gPEqS%2BGZLPAsl%2BNLgNNuxg0%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0139_a.jpg?sp=r&st=2020-12-20T02:08:23Z&se=2022-12-20T10:08:23Z&spr=https&sv=2019-12-12&sr=b&sig=04Pg53Mg8FAAZG9T%2FNmawDPa7OsIAB%2Fhj6uJNFcZegU%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0140_a.jpg?sp=r&st=2020-12-20T02:08:41Z&se=2022-12-20T10:08:41Z&spr=https&sv=2019-12-12&sr=b&sig=xoNZHwQlU%2BdlkvJ9z4x5oPmb6xAfYHrbH9TaHlVLxXo%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0144_a.jpg?sp=r&st=2020-12-20T02:09:00Z&se=2022-12-20T10:09:00Z&spr=https&sv=2019-12-12&sr=b&sig=43ClzC7goKGCHm0iFwpk6RWTG6s%2F%2FO4gC64MjuJbOTs%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0145_a.jpg?sp=r&st=2020-12-20T02:09:18Z&se=2022-12-20T10:09:18Z&spr=https&sv=2019-12-12&sr=b&sig=FzcewxrpXpg6HnwKGHTfRekeYdBFZrU9Sxav9%2BXz2bw%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0149_a.jpg?sp=r&st=2020-12-20T02:09:33Z&se=2022-12-20T10:09:33Z&spr=https&sv=2019-12-12&sr=b&sig=L0dDMxQDYC63vkd7BNdzKTCNZz7SF8jbUKQPuPEscjk%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0179_a.jpg?sp=r&st=2020-12-20T02:09:51Z&se=2022-12-20T10:09:51Z&spr=https&sv=2019-12-12&sr=b&sig=Dm5HDJobQc1p6gI81UAtV%2FrxU7juP%2Fv8kbJbBM%2B9ips%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0181_a.jpg?sp=r&st=2020-12-20T02:10:07Z&se=2022-12-20T10:10:07Z&spr=https&sv=2019-12-12&sr=b&sig=wMGAZqQhLYVhjnJdmwVCaQ28oi%2BgIJAUYmwWnIExF0w%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0186_a.jpg?sp=r&st=2020-12-20T02:10:24Z&se=2022-12-20T10:10:24Z&spr=https&sv=2019-12-12&sr=b&sig=lIBzUlZqdkHXU%2Fk44eIAwftzqdHFfsqcAVOnWb6CDqw%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0192_a.jpg?sp=r&st=2020-12-20T02:10:41Z&se=2022-12-20T10:10:41Z&spr=https&sv=2019-12-12&sr=b&sig=Gj6JfYlI2byeAbGVcSBQAdxpIE8TjOBL%2BJ0dBMjBd34%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0243_a.jpg?sp=r&st=2020-12-20T02:10:57Z&se=2022-12-20T10:10:57Z&spr=https&sv=2019-12-12&sr=b&sig=jmBw1m62%2FQEjMtdkqbJWfj8Vezh%2BjWv0mZ7Etn%2FFNUY%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0266_a.jpg?sp=r&st=2020-12-20T02:11:13Z&se=2022-12-20T10:11:13Z&spr=https&sv=2019-12-12&sr=b&sig=Tfh4H3nY6EmSrIY2cyS%2BlGY3oHZ%2F6yrAQF2JEuP0yC4%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0276_a.jpg?sp=r&st=2020-12-20T02:11:30Z&se=2022-12-20T10:11:30Z&spr=https&sv=2019-12-12&sr=b&sig=775uMlvw4%2B3YmlRd8f4fMSEEGzrzCb9EHRYIGTUZx%2BM%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0286_b.jpg?sp=r&st=2020-12-20T02:11:48Z&se=2022-12-20T10:11:48Z&spr=https&sv=2019-12-12&sr=b&sig=On1Iq5lwG%2FF16zyJJKuS85wULi4Dm30JXnV0X0zy9l0%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0298_a.jpg?sp=r&st=2020-12-20T02:12:33Z&se=2022-12-20T10:12:33Z&spr=https&sv=2019-12-12&sr=b&sig=ZPrEcc1z1vPy7bcFhZBDkTe%2FNRMH7638aAGRluGIKhI%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0300_b.jpg?sp=r&st=2020-12-20T02:12:51Z&se=2022-12-20T10:12:51Z&spr=https&sv=2019-12-12&sr=b&sig=ifGX2EsJqZDc5TAInhDTc3MJ9iBQj10pxwor3olPkB0%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0317_a.jpg?sp=r&st=2020-12-20T02:13:09Z&se=2022-12-20T10:13:09Z&spr=https&sv=2019-12-12&sr=b&sig=4OeiIMsmM2%2Bb3EADI%2FvdzbphUfjgTtcDToGsSs0LhG0%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0326_a.jpg?sp=r&st=2020-12-20T02:13:26Z&se=2022-12-20T10:13:26Z&spr=https&sv=2019-12-12&sr=b&sig=qvUOHjfka5npabJ6MeqMXk0doRJ4qnZjkUMRieeDT5g%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0335_a.jpg?sp=r&st=2020-12-20T02:13:43Z&se=2022-12-20T10:13:43Z&spr=https&sv=2019-12-12&sr=b&sig=H5TVt2otvo9vjjrzRc%2Fp3Oeo9a4GnY3%2FzIDk0oRvEUY%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0337_a.jpg?sp=r&st=2020-12-20T02:14:00Z&se=2022-12-20T10:14:00Z&spr=https&sv=2019-12-12&sr=b&sig=BeX0MDptltCVqB3njqLeBNTdFbN5NfGcuzeiafg%2BkWY%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0338_a.jpg?sp=r&st=2020-12-20T02:14:21Z&se=2022-12-20T10:14:21Z&spr=https&sv=2019-12-12&sr=b&sig=FPCvp8B2xYbEcrfQPqL%2BCm3rciuKxyDnCJBvyxWEM6U%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0366_a.jpg?sp=r&st=2020-12-20T02:14:44Z&se=2022-12-20T10:14:44Z&spr=https&sv=2019-12-12&sr=b&sig=gPOl6BA%2Fzu9ieo3NgkMG1XSNXIUeovhHRYcQveknTEU%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0369_a.jpg?sp=r&st=2020-12-20T02:15:18Z&se=2022-12-20T10:15:18Z&spr=https&sv=2019-12-12&sr=b&sig=a2QqcYJAcGFK956KCtRIa08Drm38TAqku5eyI19cwLM%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0371_a.jpg?sp=r&st=2020-12-20T02:15:36Z&se=2022-12-20T10:15:36Z&spr=https&sv=2019-12-12&sr=b&sig=BUr9oBPy2ayC45H%2BXBBx%2FZuGCuLKskeNUFqid%2B0OAzo%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0377_a.jpg?sp=r&st=2020-12-20T02:15:53Z&se=2022-12-20T10:15:53Z&spr=https&sv=2019-12-12&sr=b&sig=ZJX14IRskb4JF1Dqg8kW7LVp9y0D7C1nAu4XUDBqPc8%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0381_b.jpg?sp=r&st=2020-12-20T02:16:14Z&se=2022-12-20T10:16:14Z&spr=https&sv=2019-12-12&sr=b&sig=FRAXzIhwOdR8NN1WSsHn%2Bl2v7fT7nsSZj6hWFfeku3I%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0539_a.jpg?sp=r&st=2020-12-20T02:16:31Z&se=2022-12-20T10:16:31Z&spr=https&sv=2019-12-12&sr=b&sig=CgJ1oLCCcsROYR8xl6U4aMKopkj1krQjIqzEGU1bKCo%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0890_a.jpg?sp=r&st=2020-12-20T02:16:51Z&se=2022-12-20T10:16:51Z&spr=https&sv=2019-12-12&sr=b&sig=sLlDCO6jLp7rx5i3ZrEQfIXXBQlIrEE8B5BA5f2ufQs%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_0978_a.jpg?sp=r&st=2020-12-20T02:17:09Z&se=2022-12-20T10:17:09Z&spr=https&sv=2019-12-12&sr=b&sig=UnkrprHDOKusaA1tZ%2BPI6UvWkQdambpMVDkeS1epqjE%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_1100_a.jpg?sp=r&st=2020-12-20T02:17:30Z&se=2022-12-20T10:17:30Z&spr=https&sv=2019-12-12&sr=b&sig=0Zvou%2BdFZXjVUFBnUF9FwBprnlGAdEa5XuKM6%2FVnI00%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_1112_a.jpg?sp=r&st=2020-12-20T02:17:48Z&se=2022-12-20T10:17:48Z&spr=https&sv=2019-12-12&sr=b&sig=uwx2c4AJaCuPh9ZhV1rJ0fP6CHIjwgur9fafHSsZ5ok%3D'},
                { src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/465cffef-3885-4426-af3e-1cb54572d222/Bakamus_1176_a.jpg?sp=r&st=2020-12-20T02:18:03Z&se=2022-12-20T10:18:03Z&spr=https&sv=2019-12-12&sr=b&sig=kJ9ghddmbwOiXkOdarEHMwtHDNEiG8GVi9iSJq981QQ%3D'}
            ]    
        }

        if (window.location.search.indexOf('jonesfamily') > -1) {
            document.getElementById('titleLabel').innerHTML = "Jones Family Memories";
            document.getElementById('app').classList.remove("hide");
            
            var videos = [
                {
                    id: '01',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ba01-30.m4v?sp=r&st=2020-12-24T21:40:19Z&se=2025-12-25T05:40:19Z&spr=https&sv=2019-12-12&sr=b&sig=GUWHJMXqKM6kr4Y%2FvhZkbwKa%2Fv1zLKs3mrpRXvzu32Y%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '2000 Bondurant Final Race',
                    subtitle: '(Full Video - 01:00:03)',
                    canPlay: false
                },
                {
                    id: '02',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ba02-31.m4v?sp=r&st=2020-12-24T21:42:45Z&se=2025-12-25T05:42:45Z&spr=https&sv=2019-12-12&sr=b&sig=0kUnfptTDZ07vAExCUFB2NpbM%2B8SQMvzV7shOH4rkFE%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '2005 - Greek Easter',
                    subtitle: '(Full Video - 00:12:27)',
                    canPlay: false
                },
                {
                    id: '03',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ba03-32.m4v?sp=r&st=2020-12-24T21:44:25Z&se=2025-12-25T05:44:25Z&spr=https&sv=2019-12-12&sr=b&sig=KYRs0ztbT3xJf5URc0ytpElXe%2B0lpQuq%2BeQ5%2BvExu1g%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1995 - Mediterranean Cruise',
                    subtitle: '(Full Video - 01:43:20)',
                    canPlay: false
                },
                {
                    id: '04',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ba04-33.m4v?sp=r&st=2020-12-24T21:44:50Z&se=2025-12-25T05:44:50Z&spr=https&sv=2019-12-12&sr=b&sig=z3IwbCb5YvOkIV%2BcnUX3PXJpJ6nn1DH4Yu7tnLcPMxo%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1999 - England/Paris/Wales',
                    subtitle: '(Full Video - 01:32:09)',
                    canPlay: false
                },                {
                    id: '05',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ba05-34.m4v?sp=r&st=2020-12-24T21:45:15Z&se=2025-12-25T05:45:15Z&spr=https&sv=2019-12-12&sr=b&sig=jxSeu4VH3XJJTtVlMwvtfnchC5fqbRfbzPsIqv0EBW4%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1995 - Australia / Mediterranean',
                    subtitle: '(Full Video - 02:03:45)',
                    canPlay: false
                },                {
                    id: '06',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ba06-35.m4v?sp=r&st=2020-12-24T21:45:35Z&se=2025-12-25T05:45:35Z&spr=https&sv=2019-12-12&sr=b&sig=QjGtSGbjuzlXwYOqvEZWf4VwsRnYQ6PYO8gOMpZ4j90%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1991 - Liberty Rugby / Shane Jackass',
                    subtitle: '(Full Video - 02:03:51)',
                    canPlay: false
                },                {
                    id: '07',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bb01-36.m4v?sp=r&st=2020-12-24T21:46:01Z&se=2025-12-25T05:46:01Z&spr=https&sv=2019-12-12&sr=b&sig=ThJqTLACZIdBMXhP3mvORI3r0fBrCphdQcZaPAwaysA%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1996 - Dakotas',
                    subtitle: '(Full Video - 01:32:13)',
                    canPlay: false
                },                {
                    id: '08',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bb02-37.m4v?sp=r&st=2020-12-24T21:46:26Z&se=2025-12-25T05:46:26Z&spr=https&sv=2019-12-12&sr=b&sig=0WiEMJvypRmW0TmaeviBFLYPd3fUioD3lXUBZtgXIXA%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Mom/Dad Hawaii',
                    subtitle: '(Full Video - 00:19:20)',
                    canPlay: false
                },                {
                    id: '09',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bb03-38.m4v?sp=r&st=2020-12-24T21:46:48Z&se=2025-12-25T05:46:48Z&spr=https&sv=2019-12-12&sr=b&sig=JGpETKEMFRRF2lBkskczf6x04ZdyiBOhZgqrC8%2BSewo%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Shilo UW Grad/Hong Kong',
                    subtitle: '(Full Video - 00:33:36)',
                    canPlay: false
                },                {
                    id: '10',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bb04-39.m4v?sp=r&st=2020-12-24T21:47:11Z&se=2025-12-25T05:47:11Z&spr=https&sv=2019-12-12&sr=b&sig=VcdrK9w6NNu3Nh%2BV%2BqXEjPbwjGi6uUxE7CBdJNBveeI%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1993 - Japan + Random Wedding?',
                    subtitle: '(Full Video - 02:03:20)',
                    canPlay: false
                },                {
                    id: '11',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bc01-40.m4v?sp=r&st=2020-12-24T21:47:36Z&se=2025-12-25T05:47:36Z&spr=https&sv=2019-12-12&sr=b&sig=8sq%2FQn8qd0pd1%2F4qagYHJSI5bU0%2BXJtxCSXdBLBnob8%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Christmas - 90, 93, 98',
                    subtitle: '(Full Video - 01:07:51)',
                    canPlay: false
                },                {
                    id: '12',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bc02-41.m4v?sp=r&st=2020-12-24T21:48:00Z&se=2025-12-25T05:48:00Z&spr=https&sv=2019-12-12&sr=b&sig=9bOIZv69%2Bt2w9w3oNVvL%2B8dufnkM6Hukmw0sXnqC%2BKA%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1991 - Christmas',
                    subtitle: '(Full Video - 00:50:22)',
                    canPlay: false
                },                {
                    id: '13',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bc03-42.m4v?sp=r&st=2020-12-24T21:48:20Z&se=2025-12-25T05:48:20Z&spr=https&sv=2019-12-12&sr=b&sig=prEj0dB5cKTMIOLl8SJsq9i5KTf6TI1hGg1zOBJHZjw%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1995 - Alec/Dee Greece',
                    subtitle: '(Full Video - 01:33:23)',
                    canPlay: false
                },                {
                    id: '14',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bc04-43.m4v?sp=r&st=2020-12-24T21:48:42Z&se=2025-12-25T05:48:42Z&spr=https&sv=2019-12-12&sr=b&sig=MwBXW6e%2F16eGddtD29Xs7cyuqmWE46OJ%2BU0mLZy7h0Q%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1993 - Bakamus Christmas',
                    subtitle: '(Full Video - 00:37:07)',
                    canPlay: false
                },                {
                    id: '15',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bc05-44.m4v?sp=r&st=2020-12-24T21:49:07Z&se=2025-12-25T05:49:07Z&spr=https&sv=2019-12-12&sr=b&sig=yQ3VhnBhdN1YeKdiI9Wae4%2FzHvu%2BVe25qUV0Dhw8kho%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1993 - Liberty Rugby',
                    subtitle: '(Full Video - 00:12:03)',
                    canPlay: false
                },                {
                    id: '16',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bc06-45.m4v?sp=r&st=2020-12-24T21:49:29Z&se=2025-12-25T05:49:29Z&spr=https&sv=2019-12-12&sr=b&sig=dCA%2BUpEJwluImFrDfc9vArXUALRfeMUX058Ovk1wTIU%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Shane School Project / Obert Marine / Shilo Wedding',
                    subtitle: '(Full Video - 01:03:32)',
                    canPlay: false
                },                {
                    id: '17',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Bd01-46.m4v?sp=r&st=2020-12-24T21:49:50Z&se=2025-12-25T05:49:50Z&spr=https&sv=2019-12-12&sr=b&sig=wJDtIB27TbvkIGzp0So%2F66gq4OVcNf7QO5kzHk5Q9JM%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1991 - Greek Easter / Rafting / SoCal',
                    subtitle: '(Full Video - 02:00:14)',
                    canPlay: false
                },                {
                    id: '18',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Be01-47.m4v?sp=r&st=2020-12-24T21:50:10Z&se=2025-12-25T05:50:10Z&spr=https&sv=2019-12-12&sr=b&sig=8GIOxMoEJU2rKYy28dyV31N4n%2FaG3LJYiGB8L%2FEMrqY%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Grandpa B 65th Bday',
                    subtitle: '(Full Video - 00:04:24)',
                    canPlay: false
                },                {
                    id: '20',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Be02-48.m4v?sp=r&st=2020-12-24T21:50:34Z&se=2025-12-25T05:50:34Z&spr=https&sv=2019-12-12&sr=b&sig=5ksnhNp7Z0T1z1V1I7yZ07nC%2FLn3QRsL%2BNgoiis%2Fukc%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Shannon HS Grad',
                    subtitle: '(Full Video - 00:26:17)',
                    canPlay: false
                },                {
                    id: '21',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Be03-49.m4v?sp=r&st=2020-12-24T21:50:49Z&se=2025-12-25T05:50:49Z&spr=https&sv=2019-12-12&sr=b&sig=Ma4xYKhzdhXjTfNQ54%2FEq%2FcudJHM6gMpBVsvrMjlTaw%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Phoenix Trip',
                    subtitle: '(Full Video - 00:14:13)',
                    canPlay: false
                },                {
                    id: '24',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Be05-52.m4v?sp=r&st=2020-12-24T21:52:02Z&se=2025-12-25T05:52:02Z&spr=https&sv=2019-12-12&sr=b&sig=tXOTQcn3ZH152rEVpxZJZEtqJw8TR%2BPA1J5HGkmE3tQ%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1990 Thanksgiving/Xmas + 1998 Greek Easter',
                    subtitle: '(Full Video - 01:23:18)',
                    canPlay: false
                },                {
                    id: '25',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta-01-53.m4v?sp=r&st=2020-12-24T21:52:19Z&se=2025-12-25T05:52:19Z&spr=https&sv=2019-12-12&sr=b&sig=nzAYZHHLJ6WAOdoe5%2BrYuJZdIL1EA6mBzKpYCNw6Zxo%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1986 - Shannon and Shane Football',
                    subtitle: '(Full Video - 01:42:50)',
                    canPlay: false
                },                {
                    id: '26',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta-02-54.m4v?sp=r&st=2020-12-24T21:52:34Z&se=2025-12-25T05:52:34Z&spr=https&sv=2019-12-12&sr=b&sig=vk5J0tk4g3xMEZHBOCcKT2mIZHN0jJj42NSCXP0gy7Y%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Soccer - Who/When?',
                    subtitle: '(Full Video - 00:36:32)',
                    canPlay: false
                },                {
                    id: '27',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta-03-55.m4v?sp=r&st=2020-12-24T21:52:47Z&se=2025-12-25T05:52:47Z&spr=https&sv=2019-12-12&sr=b&sig=Z2EabZeWgbIV6dUqeJ%2F1C3EwTpLmeT3acmJ%2B6mPD1Fw%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Shane 7th Bday / Shilo Soccer',
                    subtitle: '(Full Video - 00:33:40)',
                    canPlay: false
                },                {
                    id: '28',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta-04-56.m4v?sp=r&st=2020-12-24T21:53:01Z&se=2025-12-25T05:53:01Z&spr=https&sv=2019-12-12&sr=b&sig=SGoF5PjcDo%2BtxMAIpKMgBnx7HxhMN4CQhwHv19xBeSw%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Shane Being a Jackass (Explicit Content) / Mom Mad (at 16:17)',
                    subtitle: '(Full Video - 00:45:32)',
                    canPlay: false
                },                {
                    id: '29',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta-05-57.m4v?sp=r&st=2020-12-24T21:53:20Z&se=2025-12-25T05:53:20Z&spr=https&sv=2019-12-12&sr=b&sig=dcnqV2DTnjrz%2Fyz3SNeUNiD5LKXFO967tAl37uil48c%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1987 Skydiving',
                    subtitle: '(Full Video - 00:36:13)',
                    canPlay: false
                },                {
                    id: '30',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta-06-60.m4v?sp=r&st=2020-12-24T21:53:33Z&se=2025-12-25T05:53:33Z&spr=https&sv=2019-12-12&sr=b&sig=AkIlsmxTrZ9sNK%2BrzXJGLmeiIV6zI0MZgUPY4EOMzB0%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Shannon/Shane Baseball',
                    subtitle: '(Full Video - 00:14:50)',
                    canPlay: false
                },                {
                    id: '31',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta06-Swimming-B-61.m4v?sp=r&st=2020-12-24T21:53:55Z&se=2025-12-25T05:53:55Z&spr=https&sv=2019-12-12&sr=b&sig=B5RAK1Tx3ZNogssDYuSbhCd8KPWcO%2FHbFP01yd5Z8%2BI%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'Yakima Swimming',
                    subtitle: '(Full Video - 00:08:00)',
                    canPlay: false
                },                {
                    id: '32',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta07-Swimming-62.m4v?sp=r&st=2020-12-24T21:54:14Z&se=2025-12-25T05:54:14Z&spr=https&sv=2019-12-12&sr=b&sig=qCTJLoDB3KL5wQI299RO%2B5TPNN495AqM%2BGHqyufwKz0%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1984 - Yakima Swimming',
                    subtitle: '(Full Video - 01:04:05)',
                    canPlay: false
                },                {
                    id: '33',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Beta08-Football-63.m4v?sp=r&st=2020-12-24T21:54:27Z&se=2025-12-25T05:54:27Z&spr=https&sv=2019-12-12&sr=b&sig=9EqQVLIK7plSjwH5EraQ8m7aMiO8dC5aYBbuyoV24dE%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1986 - Shannon/Shane Football',
                    subtitle: '(Full Video - 00:56:57)',
                    canPlay: false
                },                {
                    id: '34',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ea01-65.m4v?sp=r&st=2020-12-24T21:54:43Z&se=2025-12-25T05:54:43Z&spr=https&sv=2019-12-12&sr=b&sig=9KGbBzYUMBlPQlPHSD1T6kREg7G0qsMGfn3Wfz8WInw%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1990/91 Crystal Cruise (pt. 1)',
                    subtitle: '(Full Video - 00:55:09)',
                    canPlay: false
                },                {
                    id: '35',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ea01a-66.m4v?sp=r&st=2020-12-24T21:54:56Z&se=2025-12-25T05:54:56Z&spr=https&sv=2019-12-12&sr=b&sig=TzDRlz112gE7BBc%2FX5T2iIRI0Y1L3QIk9mkJGth4L5o%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1990/91 Crystal Cruise (pt. 2)',
                    subtitle: '(Full Video - 01:08:14)',
                    canPlay: false
                },                {
                    id: '36',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ea02-67.m4v?sp=r&st=2020-12-24T21:55:09Z&se=2025-12-25T05:55:09Z&spr=https&sv=2019-12-12&sr=b&sig=N%2FdEyLBfffrGKLDvOrBd61Rj8OnjtwNlnLhfLemamZM%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: '1990/91 Crystal Cruise (pt. 3)',
                    subtitle: '(Full Video - 01:16:22)',
                    canPlay: false
                },                {
                    id: '37',
                    src: 'https://greatmemoriessta.blob.core.windows.net/customerdata/33443db8-0ec5-4bb8-8d36-6167a27e13d6/Ea03-68.m4v?sp=r&st=2020-12-24T21:55:22Z&se=2025-12-25T05:55:22Z&spr=https&sv=2019-12-12&sr=b&sig=TqTtEYH%2F%2FBkeljZW380S3IYgBap3r1kxkCpZiQRO%2BiI%3D',
                    poster: 'play.png',
                    startTime: 1,
                    endTime: 99999,
                    previewTime: 1,
                    title: 'LHS Basketball - Who/Year? - Bad Quality',
                    subtitle: '(Full Video - 00:49:53)',
                    canPlay: false
                }
            ]
    
            var photos = [
            ]    
        }


        this.setState( { videoList: videos } )
        this.setState( { photoList: photos } )
    }

    pauseVideo(e) {
        console.log(e);
        if (!e.target.paused) {
            e.target.pause();
        }
    }

    playVideo(e) {        
        console.log(e.target.id);
        var video = document.getElementById('mainVideo');

        video.setAttribute('src', this.state.videoList[e.target.id].src);

        video.load();
        video.currentTime = this.state.videoList[e.target.id].startTime;
        video.play();

        video.addEventListener("timeupdate", this.pauseAtEndOfVideo(video, this.state.videoList[e.target.id].endTime));

        document.getElementById('overlay').classList.remove("hide");
    }

    pauseAtEndOfVideo(video, endTime) {
        if(video.currentTime >= endTime) {
            video.pause();
        }
    }
    
    previewVideo(e) {
        console.log(e.target.id);
        e.target.currentTime = this.state.videoList[e.target.id].previewTime;
        if (this.state.videoList[e.target.id].canPlay) {
            e.target.play();
        }
    }

    setCanPlay(e) {
        this.state.videoList[e.target.id].canPlay = true;
    }

    unhideMainVideoPlaer() {
        document.getElementById('mainVideoPlayer').classList.remove("hide");       
    }

    closeVideo() {
        var video = document.getElementById('mainVideo');
        video.pause();
        video.removeEventListener("timeupdate", this.pauseAtEndOfVideo(video, 0));
        document.getElementById('mainVideoPlayer').classList.add("hide");       
        document.getElementById('overlay').classList.add("hide");
    }

    closePhoto() {
        document.getElementById('mainPhotoViewer').classList.add("hide");       
        document.getElementById('overlay').classList.add("hide");
    }

    showPhoto(e) {
        var img = document.getElementById('mainPhoto');

        img.setAttribute('src', this.state.photoList[e.target.id].src);

        document.getElementById('overlay').classList.remove("hide")
        document.getElementById('mainPhotoViewer').classList.remove("hide");       
    }

    render() {
        return (
            <div id="app" className="App hide">
              <div id="overlay" className="overlay hide">
                  <img src="loading.svg" className="center" />
              </div>

              <div id="mainPhotoViewer" className="photo-viewer center hide">
                  <div className="photo-close" onClick={this.closePhoto.bind(this)} >x close</div>
                  <img id="mainPhoto"></img>
              </div>        

              <div id="mainVideoPlayer" className="video-player center hide">
                  <div className="video-close" onClick={this.closeVideo.bind(this)} >x close</div>
                <video id="mainVideo" onCanPlay={this.unhideMainVideoPlaer} controls />
              </div>
        
              <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
              </header>

              <div id="titleLabel" className="App-title"></div>

              <div className="App-subtitle">Videos</div>

              <div className="App-body">
              {this.state.videoList.map((video, index) => (
                <div key={index} className="App-card-regular">
                    <div className="video-container">
                        <img id={index} src={video.poster} onClick={this.playVideo.bind(this)} width="240" height="180" /> 
                        <div className="App-card-regular-title">{video.title}</div>
                        <div className="App-card-regular-subtitle">{video.subtitle}</div>
                    </div>
                </div>
              ))}

            <div className="App-subtitle">Photos</div>

            {this.state.photoList.map((photo, index) => (
                <div key={index} className="App-card-regular">
                    <div className="photo-container">
                        <img className="previewImage" id={index} src={photo.src} onClick={this.showPhoto.bind(this)} /> 
                    </div>
                </div>
              ))}

              </div>
            </div>
          );
    }
}

export default Home;
